// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-attendance-js": () => import("../src/templates/attendance.js" /* webpackChunkName: "component---src-templates-attendance-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */)
}

